<template>
    <form class="stretch mx-2 flex flex-row gap-3 pt-2 last:mb-2 md:last:mb-6 lg:mx-auto lg:max-w-3xl lg:pt-6">

        <!-- 三个点等待效果 -->
        <div class="relative flex h-full flex-1 flex-col">
            <div v-show="conversation.length > 1 && !fetchStatus" class="w-full flex gap-2 justify-center mb-3">
                <button
                    class="btn flex justify-center gap-2 btn-neutral" @click.stop.prevent="resetResponse"><span
                    role="img"
                    class="semi-icon semi-icon-default"><svg stroke="currentColor" fill="none" stroke-width="1.5"
                                                             viewBox="0 0 24 24" stroke-linecap="round"
                                                             stroke-linejoin="round" class="h-3 w-3" height="1em"
                                                             width="1em" xmlns="http://www.w3.org/2000/svg">
                            <polyline points="1 4 1 10 7 10"></polyline>
                            <polyline points="23 20 23 14 17 14"></polyline>
                            <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15">
                            </path>
                        </svg></span>Regenerate response
                </button>
            </div>
<!--            <div v-if="remainDay>0" class="text-center text-sm text-red-600">您当天的剩余次数为{{ remainDay }}</div>-->
<!--            <div v-if="remainDay<=0" class="text-center text-sm text-red-600">您当天的次数已经用完</div>-->

            <div v-show="fetchStatus" class="w-full flex gap-2 justify-center mb-3">
                <button
                    class="btn flex justify-center gap-2 btn-neutral" @click.stop.prevent="stopGeneration"><span
                    role="img"
                    class="semi-icon semi-icon-default"><svg stroke="currentColor" fill="none" stroke-width="1.5"
                                                             viewBox="0 0 24 24" stroke-linecap="round"
                                                             stroke-linejoin="round" class="h-3 w-3" height="1em"
                                                             width="1em" xmlns="http://www.w3.org/2000/svg">
                            <polyline points="1 4 1 10 7 10"></polyline>
                            <polyline points="23 20 23 14 17 14"></polyline>
                            <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15">
                            </path>
                        </svg></span>Stop generating
                </button>
            </div>

            <div v-show="conversation==0||conversation[conversation.length - 1].type !== 'error'"
                 class="flex flex-col w-full py-2 pl-3 md:py-3 md:pl-4 relative border border-black/10 bg-white dark:border-gray-900/50 dark:text-white dark:bg-gray-700 rounded-md shadow-[0_0_10px_rgba(0,0,0,0.10)] dark:shadow-[0_0_15px_rgba(0,0,0,0.10)]">

                <!-- textarea 高度自适应  -->

                <textarea data-id="root" v-model.trim="searchVal" @keydown.enter.ctrl.prevent="handleCtrlEnter" rows="1" placeholder="发送消息给 AI" ref="textareaRef"
                          @input="exportHeight"
                          class="m-0 w-full resize-none border-0 outline-0 bg-transparent p-0 pr-7 focus:ring-0 focus-visible:ring-0 dark:bg-transparent overflow-auto text-area-overflow hidden-scroll-bar"
                          style="max-height: 200px;" @keydown.enter.prevent.stop.exact="handleGetData"></textarea>
                <button v-if="!fetchStatus" @click.prevent.stop="handleGetData"
                        class="send-btn absolute p-1 rounded-md text-gray-500 bottom-1.5 right-1 md:bottom-2.5 md:right-2 hover:bg-gray-100 dark:hover:text-gray-400 dark:hover:bg-gray-900 disabled:hover:bg-transparent dark:disabled:hover:bg-transparent"><span
                    role="img" class="semi-icon semi-icon-default" style="display: inline;"><svg stroke="currentColor"
                                                                                                 fill="currentColor"
                                                                                                 stroke-width="0"
                                                                                                 viewBox="0 0 20 20"
                                                                                                 class="h-4 w-4 rotate-90"
                                                                                                 height="1em"
                                                                                                 width="1em"
                                                                                                 xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z">
                            </path>
                        </svg></span></button>
                <div v-if="fetchStatus" class="absolute right-8 top-1 w-2 ">
                    <div class="lds-ellipsis ">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>


<script>
import {ref} from 'vue'

export default {
    name: "sendForm",

    props: {
        fetchStatus: {
            type: Boolean,
            default: false
        },
        conversation: Array,
        remainDay: Number,
    },

    setup(props, {emit}) {
        const searchVal = ref('');
        const textareaRef = ref(null);
        const handleGetData = (e) => {
            e.preventDefault();

            if (searchVal.value) {
                emit('handleGetData', searchVal)
            }
            textareaRef.value.style.height = '20px';
        }

        const handleCtrlEnter = (event) => {
            if (event.ctrlKey) {
                event.target.value += '\n';
                const scrollHeight = textareaRef.value.scrollHeight;
                textareaRef.value.style.height = `${scrollHeight}px`;
            }
        }


        const resetSearchVal = () => {
            searchVal.value = '';
        }

        const resetResponse = () => {
            emit('resetResponse')
        }

        const stopGeneration = () => {
            emit('stopGeneration')
        }

        const exportHeight = () => {
            console.info(textareaRef.value.scrollHeight)
            const scrollHeight = textareaRef.value.scrollHeight;
            if (textareaRef.value) {

                textareaRef.value.style.height = 'inherit';
                textareaRef.value.style.height = `${textareaRef.value?.scrollHeight}px`;
                textareaRef.value.style.overflow = `${
                    textareaRef.value?.scrollHeight > 400 ? 'auto' : 'hidden'
                }`;
            }
        }


        return {handleGetData, searchVal, resetSearchVal, resetResponse, stopGeneration, exportHeight, textareaRef,handleCtrlEnter}
    },
}
</script>


<style lang="scss">
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
}

.lds-ellipsis div {
    position: absolute;
    top: 18px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #999;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 16px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 26px;
    animation: lds-ellipsis3 0.6s infinite;
}

.bg-vert-light-gradient {
    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 13.94%, #fff 54.73%);
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(10px, 0);
    }
}</style>
